import React from "react";

import { Layout, SEO } from '../../components/structure';
import { Button, Content, Hero, PremUpgradeContainer, Testimonial, PointsLoyalty, CallToAction, LoyaltyCommerce } from "../../components/blocks"
import styled, { css } from "styled-components";
import { brand, font, mq } from "../../styles";
import GatsbyImage from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"



const IndustryRailPage = (props) => {

  const pageQuery = useStaticQuery(graphql`
    query {
      hero: file(base: { eq: "Plusgrade-Hero-Banner-Rail.jpg" }) {
       ...FullHeroBackground
      },
      rightArrowIcon: file(base: {eq: "arrow-right.png"}) {
        ...ButtonIcon
      }
      railIcon: file(base: {eq: "rail.png"}) {
        childImageSharp {
          fixed(width: 40, height: 30, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      optimizeIcon: file(base: { eq: "optimized-white.png" }) {
        childImageSharp {
            fixed(width: 60, height: 52, webpQuality: 100) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
      }
      merchandiseIcon: file(base: { eq: "merchandise-white.png" }) {
          childImageSharp {
              fixed(width: 60, height: 70, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
      engageIcon: file(base: { eq: "engage-white.png" }) {
          childImageSharp {
              fixed(width: 60, height: 52, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
      announcementIcon: file(base: { eq: "announce-orange.png" }) {
          childImageSharp {
              fixed(width: 67, height: 60, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
      searchIcon: file(base: { eq: "search-orange.png" }) {
          childImageSharp {
              fixed(width: 60, height: 60, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
      seatPlusIcon: file(base: { eq: "seatPlus-orange.png" }) {
          childImageSharp {
              fixed(width: 60, height: 60, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
      syncIcon: file(base: { eq: "sync-orange.png" }) {
          childImageSharp {
              fixed(width: 73, height: 60, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
      revPlatformBackground: file(base: { eq: "IndustryRailRevenuePlatform.jpg" }) {
        childImageSharp {
          fixed(width: 1100, height: 700, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      railTestimonials: file(base: { eq: "IndustryRailTestimonials.jpg" }) {
        childImageSharp {
          fixed(width: 1100, height: 600, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      superheroArrow: file(base: { eq: "ArrowSuperhero.png" }) {
        childImageSharp {
          fixed(width: 500, height: 300, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      mobileDevice: file(base: { eq: "Product-Rail-Premium-Upgrade.png" }) {
        childImageSharp {
            fluid(maxWidth: 260, webpQuality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
    }
    }
  `);

  const PlatformRevenueContainer = styled.div`
  
  ${ font.imports.sherika }
    width: 100%;
    height: auto;
    min-height: 700px;
    margin: auto;
    position: relative;

    .background-con {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      filter: grayscale(1) contrast(1.2);

      &:after {
        content: '';
        position: absolute;
        z-index: 350;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background:linear-gradient(0deg, rgba(0, 4, 20, 0.65), rgba(0, 4, 20, 0.65));
      }

      .gatsby-image-wrapper {
        width: 100% !important;
        height: 100% !important;
      }
    }

    .content-con {
      position: relative;
      z-index: 400;
      width: 95%;
      max-width: 1100px;
      margin: auto;
      height: 100%;
      padding: 75px 0;

      h3 {
        font-size: 38px;
        text-align: center;
        color: ${brand.colors.pg_orange};
        font-weight: 600;
        text-shadow: 0 0 40px ${brand.colors.pg_primary_dark};
      }

      .icon-content-con {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
        top: 130px;
        margin-bottom: 100px;

        @media only screen and ${mq.maxMd} {
          flex-direction: column;
          align-items: center;
        }
      }
    }

  `;

  const OutperformContent = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
    background-color: ${brand.colors.pg_primary_light};

    .left-section {
      flex-basis: 50%;
      height: 450px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .right-section {
      height: 450px;
      flex-basis: 50%;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 90px;

      .blue-highlight {
        font-size: 80px;
        color: ${brand.colors.pg_blue};
        display: block;
      }

      .copy {
        display: block;
        font-size: 24px;
        color: #000414;
        width: 54%;
        max-width: 350px;
        margin: 15px 0;
      }
    }
  `;

  const PlatformPerksContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    max-width: 800px;
    margin: auto;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    gap: 40px;
    padding: 60px 0;

    .headline-highlight{
      display: none;
    }
  `


  return (
    <Layout>
      <SEO title="Rail | Plusgrade" 
            description={'Propel incredible customer experiences with Plusgrade\'s effortless, white-label seat upgrades.'}
            keywords={'rail, train, upgrades'}/>
      <Content whiteBG>
        <Hero 
          title={'Rail'}
          image = {pageQuery['hero']}
          icon = {pageQuery['railIcon']}
          subtitle = {"Propel incredible customer experiences with effortless, white-label seat upgrades on your trains."}
          buttonURL = {'/contact/'}
          buttonLabel = {'Book a demo'}
          buttonIcon = {pageQuery['rightArrowIcon']}
          fullBG
        />

        <div>
          <PremUpgradeContainer 
            copy = {"Offer passengers the ability to bid or purchase a premium seat through a white-label user experience. Drive ancillary revenue while providing an incredible customer experience."}
            graphicStyle = {{objectPosition: "center bottom", top: "10%"}}
            image={pageQuery['mobileDevice']}
          />
        </div>
        <PlatformRevenueContainer>
          <div className="background-con">
            <GatsbyImage fixed={pageQuery['revPlatformBackground'].childImageSharp.fixed} imgStyle={{objectFit: "cover", objectPosition: "top center"}} />
          </div>
          <div className="content-con">
            <h3>The ancillary revenue platform for rail operators</h3>
            <div className="icon-content-con">
              <IconContainer 
                icon = {pageQuery['engageIcon']}
                highlight = {'Engage'}
                headline = {'with passengers across many touchpoints'}
                copy = {'Target the right passenger, at the right time, with the right offer using data intelligence and dynamic pricing capabilities.'}
              />

              <IconContainer 
                icon = {pageQuery['merchandiseIcon']}
                highlight = {'Merchandise'}
                headline = {'multiple offerings to your passengers'}
                copy = {'Bid-to-Upgrade technology allows passengers to tell you what they are willing to pay which results in ~4x more offers submitted.'}
              />

              <IconContainer 
                icon = {pageQuery['optimizeIcon']}
                highlight = {'Optimized'}
                headline = {'fulfillment to meet passengers\’ demand'}
                copy = {'Intelligent decisioning technology selects which passenger offers to upgrade, maximizing the entire train\’s revenue potential.'}
              />
            </div>
          </div>
          
        </PlatformRevenueContainer>
        <PlatformPerksContainer>
          <IconContainer twobytwo
                icon = {pageQuery['announcementIcon']}
                headline = {'Maximize\ntotal train revenue'}
                copy = {'Through advanced tools like Optimizer, Machine Learning and Dynamic Pricing, our platform is built to optimize fulfillment and meet passenger demand.'}
            />

            <IconContainer twobytwo
                icon = {pageQuery['searchIcon']}
                headline = {'Expand\n reach & engagement'}
                copy = {'Seamless integration across every digital touchpoint so you can engage your passengers at every step of their  journeys.'}
            />

            <IconContainer twobytwo
                icon = {pageQuery['seatPlusIcon']}
                headline = {'Unlock\nconsumer insights'}
                copy = {'Discover passenger price sensitivity and other learnings through Bid-to-Upgrade technology.'}
            />

            <IconContainer twobytwo
                icon = {pageQuery['syncIcon']}
                headline = {'Access direct revenue flow\n& quick integration'}
                copy = {'Go live in weeks with seamless integration into any system. Revenue from upgrade programs go directly to you.'}
            />
          
        </PlatformPerksContainer>

        <Content secondaryLightBG >
          <LoyaltyCommerce />
        </Content>

        <PointsLoyalty 
          headline={'Loyalty Commerce Platform'}
          spaceBetween/>

        <CallToAction
          title={'Drive ancillary revenue\nwith incredible customer experiences'}
          subtitle={'Contact us today to learn more.'}
          > 
          <Button destination={'/contact/request-a-demo'} label={'Request a demo'} icon={pageQuery['rightArrowIcon']} white blueText pill />
        </CallToAction>

      </Content>
    </Layout>
  );
}

export default IndustryRailPage;

const IconCon = styled.div`

${ font.imports.sherika }
  flex-basis: 24%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  h4 {
    font-family: ${font.family.sherika};
    font-weight: 600;
    color: ${brand.colors.pg_orange};
    font-size: 30px;
    margin-bottom: 5px;
  }

  .headline-highlight {
    color: ${brand.colors.pg_primary_light};
    margin-top: 30px;
  }

  p{
    margin-top: 50px;
    font-family: ${font.family.sherika};
    font-weight: 700;
    font-size: 16px;
    color: ${brand.colors.pg_primary_light};
  }

  ${props => props.twobytwo && css`
    flex-basis: 47%;
    align-items: flex-start;
    flex-wrap: wrap;

    .headline-con {
      text-align: left;
      h4 {
        color: ${brand.colors.pg_primary_dark};
        font-family: ${font.family.sherika};
        font-weight: 700;
        white-space: pre-line;
      }
      p {
        color: ${brand.colors.pg_primary_dark};
        font-family: ${font.family.sherika};
        font-weight: 400;
        margin: 20px 0;
      }
    }
  `}
`;

const IconContainer = ({icon, highlight, headline, copy, ...props }) => {
  const ic = ( icon ? <GatsbyImage fixed={icon.childImageSharp.fixed}/> : <></>);
  return(
    <IconCon {...props}>
      <div className="icon-con">
        {ic}
      </div>
      <div className="headline-con">
      <h4 className="headline-highlight">{highlight}</h4>
        <h4>
          {headline}
        </h4>
        <p>
          {copy}
        </p>
      </div>

    </IconCon>
  )
}

